// extracted by mini-css-extract-plugin
export var active = "InpageNav-module--active--ec6be";
export var buttonActive = "InpageNav-module--buttonActive--7a6a2";
export var dropdownBtn = "InpageNav-module--dropdownBtn--25484";
export var dropdownContent = "InpageNav-module--dropdownContent--ab6f3";
export var dropdownHeader = "InpageNav-module--dropdownHeader--079db";
export var dropdownSectionBtn = "InpageNav-module--dropdownSectionBtn--105e7";
export var fixed = "InpageNav-module--fixed--893ce";
export var inPageheader = "InpageNav-module--inPageheader--5c784";
export var inpageButtonContainer = "InpageNav-module--inpageButtonContainer--4ef09";
export var inpageDropdown = "InpageNav-module--inpageDropdown--378e5";
export var isNavDropdown = "InpageNav-module--isNavDropdown--a2b40";
export var isNotBtn = "InpageNav-module--isNotBtn--ce81e";
export var sectionBtn = "InpageNav-module--sectionBtn--38fec";