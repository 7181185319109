import React, { useEffect, useRef, useState } from "react";
import { createTrackingEvent, isBrowser } from "lib/tracking";
import PropTypes from "prop-types";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import * as InpageStyling from "../InpageNav/InpageNav.module.scss";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import NavigationArrow from "assets/icons/navigation_arrow.svg";

const InpageNav = ({
  isInPageNavDropdown,
  inPageLinks,
  inPageTrackingName,
}) => {
  //Tracking function
  const trackClick = (linkText) => {
    createTrackingEvent(
      `Storefront - ${inPageTrackingName} Menu Click`,
      {
        "Menu Click Name": linkText,
      },
      "Click",
      {
        "Menu Click Name": linkText,
      },
      linkText
    );
  };

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  let dropdownRef = useRef();

  const scrollToSection = (section) => {
    const sectionIndex = section;
    const sectionElement = document.querySelectorAll("main > *");
    const headerHeight = document.querySelector("header .nav").offsetHeight;
    if (sectionElement[sectionIndex]) {
      window.scroll({
        top:
          sectionElement[sectionIndex].offsetTop -
          headerHeight -
          (dropdownRef.current ? dropdownRef.current.offsetHeight : 0),
        behavior: "smooth",
      });
    }
    setIsOpenDropdown((isOpenDropdown) => !isOpenDropdown);
  };

  useEffect(() => {
    const target = document.querySelector("body main").firstElementChild;

    const observer = isBrowser() && new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (dropdownRef.current) {
            entry.isIntersecting
              ? dropdownRef.current.classList.remove(`${InpageStyling.fixed}`)
              : dropdownRef.current.classList.add(`${InpageStyling.fixed}`);
          }
          setIsOpenDropdown(false);
        });
      },
      {
        threshold: 0.01,
      }
    );

    if (typeof observer?.observe === 'function') {
      observer.observe(target);
      return () => isBrowser() && observer.disconnect();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="row">
      <div
        className={
          isInPageNavDropdown
            ? `col mono-container ${InpageStyling.inpageButtonContainer} ${InpageStyling.isNavDropdown}`
            : `col mono-container ${InpageStyling.inpageButtonContainer} hide-to-l`
        }
      >
        <div className={`${InpageStyling.inPageheader} mb-7 text6`}>
          <p className="mb-0">Jump to a section</p>
          <NavigationArrow />
        </div>

        {inPageLinks.map((link, index) => {
          return (
            <Button
              key={index}
              onClick={() => {
                scrollToSection(link.target);
                trackClick(link.trackingName);
              }}
              className={`btn btn--destructive ${InpageStyling.sectionBtn}`}
            >
              {link.text}
            </Button>
          );
        })}
      </div>

      <div
        ref={dropdownRef}
        className={
          isInPageNavDropdown
            ? `col mono-container ${InpageStyling.inpageDropdown}`
            : `col mono-container ${InpageStyling.inpageDropdown} ${InpageStyling.isNotBtn}`
        }
      >
        <Button
          primary
          onClick={() => setIsOpenDropdown((isOpenDropdown) => !isOpenDropdown)}
          className={
            isOpenDropdown
              ? `mb-0 ${InpageStyling.dropdownBtn} ${InpageStyling.buttonActive}`
              : `mb-0 ${InpageStyling.dropdownBtn}`
          }
        >
          jump to a section
          <span>
            <Icon
              name="chevron-down"
              className={
                isOpenDropdown
                  ? `arrowDropdown arrowDropdownActive`
                  : `arrowDropdown`
              }
            />
          </span>
        </Button>

        <div
          className={
            isOpenDropdown
              ? `${InpageStyling.dropdownContent} ${InpageStyling.active}`
              : `${InpageStyling.dropdownContent} `
          }
        >
          {inPageLinks.map((link, index) => {
            return (
              <div
                key={index}
                aria-hidden="true"
                onClick={() => {
                  scrollToSection(link.target);
                  trackClick(link.trackingName);
                }}
                className={InpageStyling.dropdownSectionBtn}
              >
                {link.text}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InpageNav;

InpageNav.propTypes = {
  isInPageNavDropdown: PropTypes.bool,
  inPageTrackingName: PropTypes.string,
  inPageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      trackingName: PropTypes.string,
      target: PropTypes.string,
    })
  ),
};
